import React, { useState } from "react";
import Header from "../components/Header";
import Head from "../components/Head";
import Footer from "../components/Footer";
import BoxTypo from "../components/BoxTypo";
import { Box, Grid, Button, Stack } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useForm } from "react-hook-form";
import Forms from "../components/Forms";
import ValidationView from "../components/FormsValidate";
import FirstView from "../components/LPFirstView";
import Guid from "../components/Guid";
import Flavor from "../components/Flavor";
import Kodawari from "../components/Kodawari";
import Menu from "../components/LPMenu";
import Voice from "../components/Voice";
import Plan from "../components/LPPlan";
import LPFooter from "../components/LPFooter";
import "../style/forms.css";
import "../style/base.css";
import {
  StaticImage,
  GatsbyImage,
  getImage,
  DynamicImage,
} from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import AOS from "aos";

const LP = () => {
  if (typeof document !== `undefined`) {
    console.log("find document");
    AOS.init({ duration: 800, easing: "ease-in-sine", delay: 300 });
  }
  const data = useStaticQuery(
    graphql`
      query {
        first_view_1: file(relativePath: { eq: "topFirstView/first-1.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    `
  );
  return (
    <>
      <Box>
        <FirstView />
        <div data-aos="fade-in">
          <Plan />
        </div>
        <div data-aos="fade-in">
          <Guid />
        </div>
        <div data-aos="fade-in">
          <Flavor />
        </div>
        <div data-aos="fade-in">
          <Kodawari />
        </div>
        <div data-aos="fade-in">
          <Menu />
        </div>
        <div data-aos="fade-in">
          <Voice />
        </div>
        <div data-aos="fade-in">
          <Plan />
        </div>
        <div data-aos="fade-in">
          <LPFooter />
        </div>
      </Box>
    </>
  );
};

export default LP;
