import React, { useState } from "react";
import BoxTypo from "../components/BoxTypo";
import { Box, Grid, Button } from "@mui/material";
import {
  StaticImage,
  GatsbyImage,
  getImage,
  DynamicImage,
} from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import "../style/base.css";
import { propsToClassKey } from "@mui/styles";
const Main = (props) => {
  return (
    <>
      <Box
        sx={{ display: ["block", "block", "none"] }}
        className="w100"
        style={{ height: "100vh" }}
      >
        <StaticImage
          alt="img"
          src={"../images/lp_top_sp.png"}
          placeholder="blurred"
          style={{ width: "100%", height: "100vh", objectFit: "cover" }}
        />
      </Box>
      <Box
        sx={{ display: ["none", "none", "block"] }}
        className="w100"
        style={{ height: "100vh" }}
      >
        <StaticImage
          alt="img"
          src="../images/lp_top_pc.png"
          placeholder="blurred"
          style={{ width: "100%", height: "100vh", objectFit: "cover" }}
        />
      </Box>
    </>
  );
};
export default Main;
